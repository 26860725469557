import { doc, getDoc } from "firebase/firestore";
import { db } from '../../firebase';

export const retrievePublicKey = async (userId) => {
    try {
        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
            throw new Error("User document does not exist.");
        }

        const userData = userDoc.data();
        

        const { publicKey } = userData;

        if (!publicKey) {
            throw new Error("Public key is missing.");
        }

        

        return publicKey;
    } catch (error) {
        console.error("Failed to retrieve public key:", error);
        throw error;
    }
};
