import React, { useState } from 'react';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase'; // Ensure you have the correct path to firebase
import styles from './IntroStyles.module.css';
import folderGraphics from '../../assets/notesGraphic.svg';
import secure from '../../assets/secure.svg';
import selectMeeting from '../../assets/selectMeeting.svg';
import AIFeatures from '../../assets/AIFeatures.svg';

const introScreens = [
  {
    id: 1,
    title: "Welcome to your very personal note taking tool",
    description: "We created this tool so you can store information that is relevant to you but not your team organized and efficiently.",
    image: folderGraphics
  },
  {
    id: 2,
    title: "All notes are encrypted. Only you can read them!",
    description: "The notes you take are encrypted and secure. This means that even we can’t read the content of your notes. Only you",
    image: secure
  },
  {
    id: 3,
    title: "Adding Meetings & Taking Notes",
    description: "This page is for taking notes. By clicking on the “New Meeting” button, you can add Meetings. You can then customize the title and the time of a meeting.",
    image: AIFeatures
  },
  {
    id: 4,
    title: "Selecting Meetings",
    description: "You can click on the dropdown next to the “Add Meeting” button to select recent or upcoming meetings. Meeting Notes from the past will be stored in your Notes Archive!",
    image: selectMeeting
  }
];

const Intro = ({ userId, onClose }) => {
  const [currentScreen, setCurrentScreen] = useState(0);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const handleCheckboxChange = () => {
    setDontShowAgain(!dontShowAgain);
  };

  const handleClose = async () => {
    if (dontShowAgain) {
      await setDoc(doc(db, 'users', userId), { showMeetingNotesIntro: false }, { merge: true });
    }
    onClose();
  };

  const handleNext = () => {
    if (currentScreen < introScreens.length - 1) {
      setCurrentScreen(currentScreen + 1);
    } else {
      handleClose();
    }
  };

  const { title, description, image } = introScreens[currentScreen];

  return (
    <div className={styles.intro}>
      <div className={styles.content}>
        <div className={styles.header}>
          <img src={image} alt="Custom Graphic" className={styles.iconFolder} />
        </div>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.description}>{description}</p>
        <div className={styles.actions}>
          <button onClick={handleNext} className={styles.primaryButton}>
            {currentScreen === introScreens.length - 1 ? "Let's go" : "Next"}
          </button>
          <button onClick={handleClose} className={styles.secondaryButton}>Skip</button>
        </div>
        <label className={styles.checkboxLabel}>
          <input type="checkbox" checked={dontShowAgain} onChange={handleCheckboxChange} />
          Don't show again
        </label>
      </div>
    </div>
  );
};

export default Intro;
