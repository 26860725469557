import React, { useContext, useState, useEffect } from 'react';
import ToolBar from './ToolBar';
import MeetingNotes from '../MeetingNotes';
import NotesArchive from '../NotesArchive';
import NavBar from './NavBar';
import { AuthContext } from '../../App';
import Gallery from '../Gallery';
import DemoMeetingNotes from '../DemoMeetingNotes'; // Import DemoMeetingNotes
import './animations.css'; // Make sure to import the CSS file


function DistChannel() {
    const { currentUser } = useContext(AuthContext);
    const [activePage, setActivePage] = useState('gallery');
    const [prevPage, setPrevPage] = useState('');
    const [animationClass, setAnimationClass] = useState('');
    const [renderPage, setRenderPage] = useState(''); // Track which page should be rendered

    useEffect(() => {
        if (shouldAnimate(prevPage, activePage)) {
            if (prevPage === 'meetingNotes' && activePage === 'notesArchive') {
                setAnimationClass('slide-exit-active');
                setRenderPage(prevPage);
                setTimeout(() => {
                    setAnimationClass('slide-enter');
                    setRenderPage(activePage); // Update to render the new page after the exit animation
                    setTimeout(() => {
                        setAnimationClass('slide-enter-active');
                    }, 10); // Timeout to allow CSS class to apply before transition
                }, 300); // Duration of exit animation
            } else if (prevPage === 'notesArchive' && activePage === 'meetingNotes') {
                setAnimationClass('slide-exit-active-reverse');
                setRenderPage(prevPage);
                setTimeout(() => {
                    setAnimationClass('slide-enter-reverse');
                    setRenderPage(activePage); // Update to render the new page after the exit animation
                    setTimeout(() => {
                        setAnimationClass('slide-enter-active-reverse');
                    }, 10); // Timeout to allow CSS class to apply before transition
                }, 300); // Duration of exit animation
            }
        } else {
            setPrevPage(activePage);
            setRenderPage(activePage);
        }
    }, [activePage]);

    const getPageComponent = (page) => {
        switch (page) {
            case 'meetingNotes':
                return currentUser ? <MeetingNotes onSetActivePage={setActivePage} /> : <DemoMeetingNotes />;
            case 'notesArchive':
                return currentUser ? <NotesArchive onSetActivePage={setActivePage} /> : null;
            case 'gallery':
                return <Gallery setActivePage={setActivePage} />;
            default:
                return null;
        }
    };

    const shouldAnimate = (fromPage, toPage) => {
        return (fromPage === 'meetingNotes' && toPage === 'notesArchive') ||
               (fromPage === 'notesArchive' && toPage === 'meetingNotes');
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: 'white' }}>
            <ToolBar setActivePage={setActivePage} activePage={activePage} />
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '20px', position: 'relative' }}>
                <div className={`page ${animationClass}`} style={{ position: 'absolute', width: '100%' }}>
                    {getPageComponent(renderPage)}
                </div>
            </div>
          
        </div>
    );
}

export default DistChannel;


