import React, { createContext, useState, useContext, useEffect } from 'react';
import retrieveAndDecryptPrivateKey from './retrieveAndDecryptPrivateKey';
import { retrievePublicKey } from './retrievePublicKey';

const KeyContext = createContext(null);

export const KeyProvider = ({ userId, children }) => {
    const [keys, setKeys] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const setupCrypto = async () => {
            try {
               
                const privateKey = await retrieveAndDecryptPrivateKey(userId);
               
                const publicKey = await retrievePublicKey(userId);
               
                if (!privateKey || !publicKey) {
                    throw new Error('Keys are missing');
                }
                setKeys({ privateKey, publicKey });
               
                setLoading(false);
            } catch (error) {
                console.error("Failed to setup crypto:", error);
                setTimeout(setupCrypto, 2000); // Retry after 2 seconds
            }
        };

        if (userId) {
            setupCrypto();
        }
    }, [userId]);

    return (
        <KeyContext.Provider value={{ keys, loading }}>
            {children}
        </KeyContext.Provider>
    );
};

export const useKeys = () => useContext(KeyContext);

