import React from 'react';
import styles from './Footer.module.css'; // Create a corresponding CSS file for styling
import jeffington from '../assets/jeffington.svg'


const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerContent}>
                <div className={styles.companyInfo}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <img style={{ height: '25px' }} src={jeffington}></img>
                        <h2 style={{ marginLeft: '5px' }}>Jeffington</h2>
                    </div>
                    <p style={{ fontFamily: 'Inter', fontSize: '12px', color: 'gray' }}>&copy; {new Date().getFullYear()} Jeffington. All rights reserved.</p>
                </div>
                <div className={styles.links}>
                    <p style={{ fontFamily: 'Inter', fontSize: '14px', width: '300px', fontWeight: '500', textAlign:'left' }}>Jeffington is an easily accessible suite of tools designed to empower individuals, whether for work or personal use, all supported by AI technology.

                    </p>
                </div>

            </div>
        </footer>
    );
};

export default Footer;
