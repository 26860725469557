import React, { useState, useEffect, createContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import DistChannel from './pages/distribution/DistChannel';
import { app } from './firebase';
import { GoogleApiProvider } from './pages/GoogleApiProvider';
import { KeyProvider } from './pages/encryption/KeyContext';

export const AuthContext = createContext(null);

function App() {
    const [currentUser, setCurrentUser] = useState(null);
    const auth = getAuth(app);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
            setCurrentUser(user);  // Update user state when auth state changes
        });
        return () => unsubscribe();
    }, [auth]);
   

    return (
        <GoogleApiProvider>
            <AuthContext.Provider value={{ currentUser, setCurrentUser }}>
                {currentUser ? (
                    <KeyProvider userId={currentUser.uid}>
                        <Router>
                            <Routes>
                                <Route path="/" element={<DistChannel />} />
                            </Routes>
                        </Router>
                    </KeyProvider>
                ) : (
                    <Router>
                        <Routes>
                            <Route path="/" element={<DistChannel />} />
                        </Routes>
                    </Router>
                )}
            </AuthContext.Provider>
        </GoogleApiProvider>
    );
}

export default App;


