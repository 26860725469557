import React, { useEffect, useState } from 'react';
import { getAuth, GoogleAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult, onAuthStateChanged, setPersistence, browserSessionPersistence } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from '../firebase';
import _sodium from 'libsodium-wrappers-sumo';
import retrieveAndDecryptPrivateKey from './encryption/retrieveAndDecryptPrivateKey';

const GoogleLogin = ({ setCurrentUser }) => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const initSodium = async () => {
            try {
                await _sodium.ready;
                const sodium = _sodium;
                const testSalt = sodium.randombytes_buf(sodium.crypto_pwhash_SALTBYTES);
            } catch (error) {
                console.error('Direct test - failed to generate salt:', error);
            }
        };
        initSodium();
    }, []);

    useEffect(() => {
        const auth = getAuth();

        setPersistence(auth, browserSessionPersistence)
            .then(() => {
                const handleRedirectResult = async () => {
                    try {
                        const result = await getRedirectResult(auth);
                        if (result && result.user) {
                            const user = result.user;
                            await handleUserSignIn(user);
                            setCurrentUser(user);
                        } else {
                            const currentUser = auth.currentUser;
                            if (currentUser) {
                                await handleUserSignIn(currentUser);
                                setCurrentUser(currentUser);
                            }
                        }
                    } catch (error) {
                        console.error("Failed to get redirect result:", error);
                    }
                };

                if (sessionStorage.getItem('isRedirected') === 'true') {
                    sessionStorage.removeItem('isRedirected');
                    handleRedirectResult();
                }

                onAuthStateChanged(auth, async (user) => {
                    if (user) {
                        await handleUserSignIn(user);
                        setCurrentUser(user);
                    }
                });
            })
            .catch((error) => {
                console.error('Failed to set session persistence:', error);
            });
    }, [setCurrentUser]);

    const handleGoogleSignIn = async () => {
        setIsLoading(true);
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        try {
            await signInWithPopup(auth, provider);
        } catch (error) {
            if (error.code === 'auth/popup-blocked' || error.code === 'auth/cancelled-popup-request') {
                try {
                    sessionStorage.setItem('isRedirected', 'true');
                    await signInWithRedirect(auth, provider);
                } catch (redirectError) {
                    console.error("Failed to sign in with Google using redirect:", redirectError);
                }
            } else {
                console.error("Failed to sign in with Google:", error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleUserSignIn = async (user) => {
        const userId = user.uid;
        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);
    
        if (!userDoc.exists()) {
            await _sodium.ready;
            const sodium = _sodium;
            const { publicKey, privateKey } = sodium.crypto_box_keypair();
    
            await setDoc(userDocRef, { publicKey: sodium.to_base64(publicKey) });
    
            try {
                await encryptAndStorePrivateKey(privateKey, userId);
            } catch (error) {
                console.error("Failed to store keys for user:", error);
            }
        } else {
            try {
                const privateKey = await retrieveAndDecryptPrivateKey(userId);
            } catch (error) {
                console.error("Failed to retrieve and decrypt private key:", error);
            }
        }
    };
    
    const encryptAndStorePrivateKey = async (privateKey, userId) => {
        try {
            await _sodium.ready;
            const sodium = _sodium;
    
            const salt = sodium.randombytes_buf(sodium.crypto_pwhash_SALTBYTES);
            const nonce = sodium.randombytes_buf(sodium.crypto_secretbox_NONCEBYTES);
    
            const key = sodium.crypto_pwhash(
                32,
                userId,
                salt,
                sodium.crypto_pwhash_OPSLIMIT_INTERACTIVE,
                sodium.crypto_pwhash_MEMLIMIT_INTERACTIVE,
                sodium.crypto_pwhash_ALG_DEFAULT
            );
    
            const encryptedPrivateKey = sodium.crypto_secretbox_easy(
                privateKey,
                nonce,
                key
            );
    
            await setDoc(doc(db, 'userKeys', userId), {
                encryptedPrivateKey: sodium.to_base64(encryptedPrivateKey),
                salt: sodium.to_base64(salt),
                nonce: sodium.to_base64(nonce)
            });
        } catch (error) {
            console.error("Failed to encrypt and store private key:", error);
        }
    };
    
    return (
        <div>
            <button onClick={handleGoogleSignIn} disabled={isLoading}>
                {isLoading ? "Loading..." : "Continue with Google"}
            </button>
        </div>
    );
};

export default GoogleLogin;


