import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './MeetingNotesStyles.module.css';
import { debounce } from 'lodash';
import { useCreateBlockNote } from '@blocknote/react';
import { BlockNoteView } from '@blocknote/mantine';
import '@blocknote/mantine/style.css';
import './lightTheme.css';
import Timeline from './Timeline';

const lightTheme = {
  // Define your theme here...
};

const Skeleton = () => (
  <div className={styles.skeleton}></div>
);

const demoMeetings = [
  {
    id: 'demo1',
    summary: 'Demo Meeting 1',
    start: { dateTime: new Date().toISOString() },
    end: { dateTime: new Date(new Date().getTime() + 3600000).toISOString() },
  },
  {
    id: 'demo2',
    summary: 'Demo Meeting 2',
    start: { dateTime: new Date().toISOString() },
    end: { dateTime: new Date(new Date().getTime() + 7200000).toISOString() },
  },
];

const demoContent = [
  { type: 'paragraph', content: 'This is a demo meeting note.' },
];

const DemoMeetingNotes = () => {
  const [selectedMeeting, setSelectedMeeting] = useState(demoMeetings[0]);
  const [initialContent, setInitialContent] = useState(demoContent);
  const [width, setWidth] = useState(window.innerWidth * 0.6 * 0.4);
  const editorRef = useRef(null);
  const editableTitleRef = useRef(null);

  const editor = useCreateBlockNote({
    initialContent: initialContent,
    onReady: () => {
      editorRef.current = editor;
      editor.replaceBlocks(editor.document, initialContent);
    },
  });

  const handleMeetingSelection = (meeting) => {
    setSelectedMeeting(meeting);
    setInitialContent(demoContent);
    if (editorRef.current) {
      editorRef.current.replaceBlocks(editorRef.current.document, demoContent);
    }
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  };

  const handleResize = useCallback((event, { size }) => {
    setWidth(size.width);
  }, []);

  useEffect(() => {
    const debouncedResize = debounce(() => {
      setWidth(window.innerWidth * 0.6 * 0.4);
    }, 100);

    window.addEventListener('resize', debouncedResize);
    return () => {
      window.removeEventListener('resize', debouncedResize);
      debouncedResize.cancel();
    };
  }, []);

  useEffect(() => {
    if (selectedMeeting && editableTitleRef.current) {
      editableTitleRef.current.textContent = selectedMeeting.summary;
    }
  }, [selectedMeeting]);

  const handleTitleChange = (event) => {
    if (!selectedMeeting) return;
    const newTitle = event.target.textContent;
    selectedMeeting.summary = newTitle;
  };

  return (
    <div className={styles.container}>
      <Timeline meetings={demoMeetings} onMeetingClick={handleMeetingSelection} />
      <div className={styles.contentContainer}>
        {selectedMeeting ? (
          <div className={styles.selectedMeeting}>
            <h2
              ref={editableTitleRef}
              contentEditable
              suppressContentEditableWarning
              onBlur={handleTitleChange}
              className={styles.editableTitle}
            >
              {selectedMeeting.summary}
            </h2>
            <p>{formatDate(new Date(selectedMeeting.start.dateTime))}</p>
          </div>
        ) : (
          <Skeleton />
        )}
        <div className={styles.flexContainer}>
          <div className={styles.meetingNotesContainer}>
            <div className={`${styles.meetingNotesEditor} light-theme-editor`}>
              <BlockNoteView editor={editor} theme={lightTheme} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoMeetingNotes;
