// encryption.js
import sodium from 'libsodium-wrappers';

export const initializeSodium = async () => {
    await sodium.ready;
    return sodium;
}

export const encryptData = async (message, publicKey) => {
    await sodium.ready;
    if (!message) {
        throw new Error('Message cannot be null or undefined');
    }

    if (!publicKey) {
        throw new Error('Public key cannot be null or undefined');
    }

    const encoder = new TextEncoder();
    const encodedMessage = encoder.encode(message);
    const publicKeyUint8 = sodium.from_base64(publicKey);

    if (publicKeyUint8.length !== sodium.crypto_box_PUBLICKEYBYTES) {
        throw new Error(`Invalid publicKey length: expected ${sodium.crypto_box_PUBLICKEYBYTES}, got ${publicKeyUint8.length}`);
    }

    const encrypted = sodium.crypto_box_seal(encodedMessage, publicKeyUint8);
    return btoa(String.fromCharCode(...new Uint8Array(encrypted)));
};


export const decryptData = async (encryptedBase64, publicKey, privateKey) => {
    await sodium.ready;
    

    const encrypted = Uint8Array.from(atob(encryptedBase64), c => c.charCodeAt(0));
    const publicKeyUint8 = sodium.from_base64(publicKey);
    const privateKeyUint8 = sodium.from_base64(privateKey);

   

    const decrypted = sodium.crypto_box_seal_open(encrypted, publicKeyUint8, privateKeyUint8);

    const decoder = new TextDecoder();
    return decoder.decode(decrypted);
};
