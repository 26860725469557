import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Timeline.css';

const Timeline = ({ meetings, onMeetingClick, onAddMeetingClick }) => {
  const hours = Array.from({ length: 24 }, (_, index) => index);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [activeMeetingId, setActiveMeetingId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const timelineRef = useRef(null);

  const datePickerStyle = {
    backgroundColor: '#F9F9F9',
    borderRadius: '10px',
    cursor: 'pointer',
    color: '#959595',
    paddingLeft: '0px',
    alignItems: 'center',
    alignText: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '160px',
    border: '1px solid #DEDCDC',
    fontFamily: 'Inter',
    fontSize: '14px',
    display: 'flex',
    padding: '15px'
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    scrollToCurrentTime();
  }, [currentTime]);

  useEffect(() => {
    scrollToCurrentTime();
  }, []);

  const handleMeetingClick = (meeting) => {
    setActiveMeetingId(meeting.id);
    onMeetingClick(meeting);
  };

  const calculatePosition = (hours, minutes = 0) => {
    const totalMinutes = hours * 60 + minutes;
    // Each hour is 60px tall, adjust accordingly
    return (totalMinutes / 60) * 60; // Convert minutes to pixels
  };

  const calculateHeight = (startDateTime, endDateTime) => {
    const startDate = new Date(startDateTime);
    const endDate = new Date(endDateTime);
    const durationInMinutes = (endDate - startDate) / 60000; // Convert duration to minutes

    // Each hour is 60px tall, adjust accordingly
    return (durationInMinutes / 60) * 60; // Convert minutes to pixels
  };

  const scrollToCurrentTime = () => {
    if (timelineRef.current) {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      const currentMinutes = currentTime.getMinutes();
      const timePosition = calculatePosition(currentHour, currentMinutes);
      timelineRef.current.scrollTop = timePosition - timelineRef.current.clientHeight / 2;
    }
  };

  const filterMeetingsByDate = (meetings, date) => {
    const selectedDateString = date.toISOString().split('T')[0];
    return meetings.filter((meeting) => {
      const meetingDateString = new Date(meeting.start.dateTime).toISOString().split('T')[0];
      return meetingDateString === selectedDateString;
    });
  };

  const filteredMeetings = filterMeetingsByDate(meetings, selectedDate);

  const formatDate = (date) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  return (
    <div className="timelineContainer">
      <div className='timelineContentContainer'>
        <div className="header">
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="d. MMMM yyyy"
            customInput={<input style={datePickerStyle} value={formatDate(selectedDate)} readOnly />}
          />
        </div>
        <div className="timeLabels">
          {hours.map((hour) => (
            <div key={hour} className="timeLabel">
              {hour < 10 ? `0${hour}:00` : `${hour}:00`}
            </div>
          ))}
        </div>
        <div className="timeline" ref={timelineRef}>
          {filteredMeetings.map((meeting) => (
            <div
              key={meeting.id}
              className={`meetingCard ${meeting.id === activeMeetingId ? 'active' : ''}`}
              onClick={() => handleMeetingClick(meeting)}
              style={{
                top: `${calculatePosition(new Date(meeting.start.dateTime).getHours(), new Date(meeting.start.dateTime).getMinutes())}px`,
                height: `${calculateHeight(meeting.start.dateTime, meeting.end.dateTime)}px`,
              }}
            >
              {meeting.summary}
            </div>
          ))}
          <div className="currentTimeMarker" style={{ top: `${calculatePosition(currentTime.getHours(), currentTime.getMinutes())}px` }}>
            <div className="currentTime">
              {currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;