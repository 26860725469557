// retrieveAndDecryptPrivateKey.js
import { doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import _sodium from 'libsodium-wrappers-sumo';
import { db } from "../../firebase";

const retrieveAndDecryptPrivateKey = async (userId) => {
    try {
        await _sodium.ready;
        const sodium = _sodium;

        // Fetch the encrypted private key data from Firestore
        const docRef = doc(db, 'userKeys', userId);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
            throw new Error("User key data does not exist.");
        }

        const { encryptedPrivateKey, salt, nonce } = docSnap.data();

        // Log the retrieved values

        // Convert from base64 to Uint8Array
        const encryptedPrivateKeyUint8 = sodium.from_base64(encryptedPrivateKey);
        const saltUint8 = sodium.from_base64(salt);
        const nonceUint8 = sodium.from_base64(nonce);

        // Derive the key from userId and salt
        const key = sodium.crypto_pwhash(
            32, // output length in bytes
            userId, // password (here, userId)
            saltUint8, // salt
            sodium.crypto_pwhash_OPSLIMIT_INTERACTIVE, // operations limit
            sodium.crypto_pwhash_MEMLIMIT_INTERACTIVE, // memory limit
            sodium.crypto_pwhash_ALG_DEFAULT // algorithm
        );

        // Decrypt the private key
        const privateKeyUint8 = sodium.crypto_secretbox_open_easy(
            encryptedPrivateKeyUint8,
            nonceUint8,
            key
        );

        const privateKey = sodium.to_base64(privateKeyUint8);
       

        return privateKey;
    } catch (error) {
        console.error("Failed to retrieve and decrypt private key:", error);
        throw error;
    }
};

export default retrieveAndDecryptPrivateKey;
