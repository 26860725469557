// RiveAnimation.js
import React from 'react';
import { useRive, Layout, Fit, Alignment } from '@rive-app/react-canvas';

const RiveAnimation = () => {
  const { RiveComponent } = useRive({
    src: 'character.riv', // Update this path to your actual file location
    stateMachines: 'Motion', // Replace with your state machine name if different
    layout: new Layout({
      fit: Fit.FitWidth, // Change to: Fit.Contain, or Fit.Cover
      alignment: Alignment.Center,
    }),
    autoplay: true,
  });

  return <RiveComponent />;
};

export default RiveAnimation;

