import React, { useState, useEffect } from 'react';
import OpenAI from 'openai';
import styles from './AIActionsStyles.module.css';
import sparkles from '../assets/create.svg'

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true // Allow usage in browser for testing purposes
});

const placeholders = ["Summarize this", "Sort my thoughts", "Generate action items", "Create summary"];

const AIActionItems = ({ meetingNotes, onUpdateMeetingNotes, disableUpdates }) => {
  const [actionItems, setActionItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customPrompt, setCustomPrompt] = useState("");
  const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholders[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPlaceholder((prev) => {
        const currentIndex = placeholders.indexOf(prev);
        const nextIndex = (currentIndex + 1) % placeholders.length;
        return placeholders[nextIndex];
      });
    }, 6000); 

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  useEffect(() => {
    console.log('Received meeting notes:', JSON.stringify(meetingNotes, null, 2));
  }, [meetingNotes]);

  const generateActionItems = async () => {
    if (disableUpdates) {
      console.log('Updates are disabled. Skipping AI action item generation.');
      return;
    }

    setLoading(true);
    try {
      const response = await openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: "You are meeting notes tool that will be provided with meeting notes, and your task is to follow the user's prompt. If the meeting notes is too short answer 'Your notes are a bit short for AI features, don't you think? 🙃'"
          },
          {
            role: "user",
            content: customPrompt + '\n\n' + JSON.stringify(meetingNotes, null, 2), // Include the custom prompt
          }
        ],
        temperature: 0.7,
        max_tokens: 256,
        top_p: 1,
      });

      const actionItemsContent = response.choices[0].message.content.trim();
      const actionItemsArray = actionItemsContent.split('\n');
      setActionItems(actionItemsArray);

      // Integrate AI-generated action items into Block Editor structure
      const aiBlocks = actionItemsArray.map(item => ({
        type: 'paragraph',
        content: item
      }));

      // Example of how you might integrate with the existing meeting notes (assuming meetingNotes is an array of blocks)
      const updatedMeetingNotes = [...meetingNotes, ...aiBlocks];

      // Log the updated meeting notes with AI snippets
      console.log('Updated meeting notes with AI snippets:', JSON.stringify(updatedMeetingNotes, null, 2));

      // Update the parent component with the new meeting notes
      onUpdateMeetingNotes(updatedMeetingNotes);

    } catch (error) {
      console.error("Error generating action items:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.inputContainer}>
      
      <input
        type="text"
        value={customPrompt}
        onChange={(e) => setCustomPrompt(e.target.value)}
        placeholder={loading ? "Generating..." : currentPlaceholder}
        className={styles.promptInput}
        disabled={loading}
      />
       <button onClick={generateActionItems} className={styles.button} disabled={loading}>
        <img src={sparkles} className={styles.icon} alt="sparkles" />
      </button>
    </div>
  );
};

export default AIActionItems;
