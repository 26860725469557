import React from 'react';
import { getAuth } from 'firebase/auth';
import styles from './Gallery.module.css';
import RiveAnimation from './RiveAnimation';
import Footer from './Footer';
import hackerNews from '../assets/HackerNews_logo.png';
import productHunt from '../assets/ProductHunt_logo.png'
import star from '../assets/star.svg'


function Gallery({ setActivePage }) {
    const handleStartNotes = () => {
        const auth = getAuth();

        setActivePage('meetingNotes');
    };

    return (
        <div className={styles.container}>
            <div className={styles.contentContainer}>
                <div className={styles.headerDiv}>
                    <div className={styles.animationDiv}>
                        <RiveAnimation />
                        <p className={styles.getOffLabel}>Click on animation for a little treat ;)</p>
                    </div>
                    <h2 className={styles.heading}>
                        Your suite of <span className={styles.highlight}>easy, personal tools</span> that just work
                    </h2>
                    <p className={styles.subHeading}>We thought it might be a good idea to develop tools that actually focus on individuals and not on teams. </p>
                    <button onClick={handleStartNotes} className={styles.buttonDemo}>→ Try notes demo</button>
                    <p className={styles.subButtonLabel}>No sign-up required</p>
                    <div className={styles.testimonialsDiv}>
                        <img></img>
                        <img></img>
                        <img></img>
                        <div className={styles.testimonialsDivRight}>
                            <div className={styles.ratingDiv}>
                                <img src={star}></img>
                                <img src={star}></img>
                                <img src={star}></img>
                                <img src={star}></img>
                                <img src={star}></img>
                            </div>
                            <p>23 happy customer</p>
                        </div>

                    </div>
                </div>


                <div className={styles.toolContentDiv}>
                    <div className={styles.toolContentHeader}>
                        <p style={{ color: '#777672' }}>Tools</p>
                    </div>

                    <div className={styles.meetingNotesTool}>
                        <p className={styles.statusTag}>Tool is available</p>
                        <h1 className={styles.h1}>A simple Note Taking tool that organizes your meeting notes automatically. </h1>
                        <p className={styles.p}>
                            It includes a page to take meeting notes with <span className={styles.pHighlight}>AI writing features</span> and your <span className={styles.pHighlight}>own Notes Archive</span> built in. You can also download all your notes to a <span className={styles.pHighlight}>.html file</span>.
                        </p>
                        <div className={styles.ctaDiv}>
                            <button className={styles.buttonTryDemo} onClick={handleStartNotes}>Try it in demo mode</button>
                            <p className={styles.seperator}>or</p>
                            <button className={styles.buttonGetTool}>Get tool for 9.99€</button>
                        </div>
                        <vid></vid>
                        <p>Write notes in a great Editor with embedded AI writing tools
                            Store your notes in the Notes Archive
                            Download all your notes onto your device if you like to be independent from any app</p>
                    </div>
                    <p>More tools are coming soon </p>
                    <div className={styles.featuredOnDiv}>
                        <p>Feature on</p>
                        <img src={hackerNews} className={styles.logoImages}></img>
                        <img src={productHunt} className={styles.logoImages}></img>
                    </div>
                    <div className={styles.aboutUs}>
                        About us
                    </div>
                </div>
                <Footer></Footer>
            </div>
        </div>
    );
}

export default Gallery;

