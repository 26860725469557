import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../App';
import FeedbackForm from '../FeedbackForm';
import tools from '../../assets/tools.svg';
import jefferson from '../../assets/jeffington.svg';
import GoogleLogin from '../GoogleLogin';
import lock from '../../assets/lock.svg';
import styles from './ToolBar.module.css';

function ToolBar({ setActivePage, activePage, handleTemplateClick }) {
    const { currentUser, setCurrentUser } = useContext(AuthContext);
    const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [isHovering, setIsHovering] = useState(false);
    const [userDropdownOpen, setUserDropdownOpen] = useState(false);
    const [userHovering, setUserHovering] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const handleFeedbackSubmit = (feedback) => {
        setIsFeedbackOpen(false);
    };

    useEffect(() => {
        document.body.style.margin = '0';
        return () => {
            document.body.style.margin = '';
        };
    }, []);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 1024);
        };

        window.addEventListener('resize', checkMobile);
        checkMobile();

        return () => {
            window.removeEventListener('resize', checkMobile);
        };
    }, []);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
        setIsHovering(false);
    };

    const toggleUserDropdown = () => {
        setUserDropdownOpen(!userDropdownOpen);
        setUserHovering(false);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
        if (!dropdownOpen) {
            setDropdownOpen(false);
        }
    };

    const handleUserMouseLeave = () => {
        setUserHovering(false);
        if (!userDropdownOpen) {
            setUserDropdownOpen(false);
        }
    };

    const handleLogout = () => {
        setCurrentUser(null);
        // Add any additional logout logic here (e.g., API calls, clearing tokens)
    };

    const dropdownItemStyle = (itemName) => ({
        padding: '4px 20px',
        borderRadius: '6px',
        cursor: 'pointer',
        backgroundColor: hoveredItem === itemName ? '#E8E8E8' : 'white',
        fontWeight: activePage === itemName ? 'bold' : 'normal',
        color: activePage === itemName ? 'black' : '#777672',
        transition: 'background-color 0.2s',
    });

    function getDisplayName(page) {
        const pageNames = {
            'meetingNotes': 'Meeting notes',
            'notesArchive': 'Meeting notes',
            'gallery': 'Tools Gallery',
        };

        return pageNames[page] || page;
    }

    return (
        <div className={styles.toolbar}>
            <div className={styles.toolbarContent}>
            <div className={styles.leftSection}>
                <div
                    className={styles.dropdownToggle}
                    onClick={toggleDropdown}
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={handleMouseLeave}
                >
                    <img src={jefferson} alt="Descriptive Alt Text" className={styles.icon} />
                    <span className={styles.centerText}>jeffington {'>'}</span>
                    
                    <p style={{ fontFamily: 'Inter', fontWeight: '600', color: '#777672', fontSize: '14px', lineHeight: '0px' }}>{getDisplayName(activePage)}</p>
                    {dropdownOpen && (
                        <div className={styles.dropdownContent}>
                            <div onMouseEnter={() => setHoveredItem('concept')} onMouseLeave={() => setHoveredItem(null)} onClick={() => { setActivePage('gallery'); toggleDropdown(); }} style={dropdownItemStyle('concept')}>
                                <p style={{ fontFamily: 'Inter', fontWeight: '500', fontSize: '14px', lineHeight: '0px' }}>Tools Gallery</p>
                            </div>
                            <div className={styles.dropdownDivider}></div>
                            <div onMouseEnter={() => setHoveredItem('prepNotes')} onMouseLeave={() => setHoveredItem(null)} onClick={() => { setActivePage('meetingNotes'); toggleDropdown(); }} style={dropdownItemStyle('prepNotes')}>
                                <p style={{ fontFamily: 'Inter', fontWeight: '500', fontSize: '14px', lineHeight: '0px' }}>Meeting notes</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {activePage === 'concept' && (
                <div className={styles.centerSection}>
                    <img src={jefferson} alt="Descriptive Alt Text" className={styles.icon} />
                    <span className={styles.centerText}>jeffington | Simple AI tools for you! </span>
                    <img src={herb} alt="Descriptive Alt Text" className={styles.icon} />
                    <img src={sun} alt="Descriptive Alt Text" className={styles.icon} />
                </div>
            )}

            {activePage === 'notesArchive' && (
                <div className={styles.centerSection}>
                    <img className={styles.lockIcon} src={lock} alt="lock icon" />
                    <p className={styles.encryptionText}>All notes are end to end encrypted</p>
                </div>
            )}

            {activePage === 'meetingNotes' && (
                <div className={styles.centerSection}>
                    <img className={styles.lockIcon} src={lock} alt="lock icon" />
                    <p className={styles.encryptionText}>All notes are end to end encrypted</p>
                </div>
            )}

            <div className={styles.rightSection}>
                <button onClick={() => setIsFeedbackOpen(true)} className={styles.feedbackButton}>Quick Feedback</button>
                {isFeedbackOpen && <FeedbackForm onClose={() => setIsFeedbackOpen(false)} onSubmit={handleFeedbackSubmit} />}
                {currentUser ? (
                    <div
                        className={styles.userDropdownToggle}
                        onClick={toggleUserDropdown}
                        onMouseEnter={() => setUserHovering(true)}
                        onMouseLeave={handleUserMouseLeave}
                    >
                        <span style={{ fontFamily: 'Inter', fontWeight: '600', fontSize: '14px', marginRight: '10px' }}>{currentUser.displayName}</span>
                        {userDropdownOpen && (
                            <div className={styles.userDropdownContent}>
                                <div onMouseEnter={() => setHoveredItem('logout')} onMouseLeave={() => setHoveredItem(null)} onClick={handleLogout} style={dropdownItemStyle('logout')}>
                                    <p style={{ fontFamily: 'Inter', fontWeight: '500', fontSize: '14px', lineHeight: '0px' }}>Logout</p>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    isMobile ? (
                        <button className={styles.feedbackButton}>Continue on desktop</button>
                    ) : (
                        <GoogleLogin setCurrentUser={setCurrentUser} />
                    )
                )}
            </div>
            </div>
        </div>
    );
}

export default ToolBar;


