import React, { useState, useEffect, useContext, useRef } from 'react';
import { doc, getDoc, setDoc, collection, query, where, onSnapshot, orderBy, limit } from 'firebase/firestore';
import { db } from '../firebase';
import { AuthContext } from '../App';
import styles from './MeetingNotesStyles.module.css';
import { debounce } from 'lodash';
import { decryptData, encryptData } from './encryption/cryptoUtils';
import { useKeys } from './encryption/KeyContext';
import '@blocknote/core/fonts/inter.css';
import { useCreateBlockNote } from '@blocknote/react';
import { BlockNoteView } from '@blocknote/mantine';
import '@blocknote/mantine/style.css';
import './lightTheme.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import plus from '../assets/calendar_plus.svg'
import AIActionItems from './AIActions';
import Intro from './Introduction/Tutorial1';

const Skeleton = () => (
  <div className={styles.skeleton}></div>
);

const demoMeetings = [
  {
    id: 'demo1',
    summary: 'Demo Meeting 1',
    start: { dateTime: new Date().toISOString() },
    end: { dateTime: new Date(new Date().getTime() + 3600000).toISOString() },
  },
  {
    id: 'demo2',
    summary: 'Demo Meeting 2',
    start: { dateTime: new Date().toISOString() },
    end: { dateTime: new Date(new Date().getTime() + 7200000).toISOString() },
  },
];

const demoContent = [
  { type: 'paragraph', content: 'This is a demo meeting note.' },
];



const MeetingNotes = ({ onSetActivePage }) => {
  const { keys } = useKeys();
  const { currentUser } = useContext(AuthContext);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [selectedMeetingId, setSelectedMeetingId] = useState(null);
  const [meetings, setMeetings] = useState([]);
  const [initialContent, setInitialContent] = useState([{ type: 'paragraph', content: '' }]);
  const [meetingNotesInitialContent, setMeetingNotesInitialContent] = useState([{ type: 'paragraph', content: '' }]);
  const editorRef = useRef(null);
  const meetingNotesEditorRef = useRef(null);
  const selectedMeetingRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const editableTitleRef = useRef(null);
  const events = [];
  const [showAddMeetingForm, setShowAddMeetingForm] = useState(false);
  const [newMeetingName, setNewMeetingName] = useState('');
  const [newMeetingStart, setNewMeetingStart] = useState(new Date());
  const [newMeetingEnd, setNewMeetingEnd] = useState(new Date());
  const [isEditingStart, setIsEditingStart] = useState(false);
  const [isEditingEnd, setIsEditingEnd] = useState(false);
  const [isEditingDate, setIsEditingDate] = useState(false);
  const [meetingNotes, setMeetingNotes] = useState("");
  const [componentKey, setComponentKey] = useState(0);
  const [disableUpdates, setDisableUpdates] = useState(false);
  const [showIntro, setShowIntro] = useState(false);
  const [introChecked, setIntroChecked] = useState(false);


  useEffect(() => {
    if (keys) {
      const { privateKey, publicKey } = keys;
    }
  }, [keys]);


  useEffect(() => {
    const checkIntroPreference = async () => {
      if (currentUser && currentUser.uid) {
        const userRef = doc(db, 'users', currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setShowIntro(userSnap.data().showMeetingNotesIntro !== false);
        }
      }
      setIntroChecked(true); // Mark intro check as complete
    };

    checkIntroPreference();
  }, [currentUser]);

  const handleCloseIntro = () => {
    setShowIntro(false);
  };


  const editor = useCreateBlockNote({
    initialContent: initialContent,
    onReady: () => {
      editorRef.current = editor;
      if (selectedMeeting) {
        getPrepNoteContent(selectedMeeting);
      }
    },
    onChange: async () => {
      if (selectedMeetingRef.current) {
        await savePrepNote();
      }
    }
  });

  const meetingNotesEditor = useCreateBlockNote({
    initialContent: meetingNotesInitialContent,
    onReady: () => {
      meetingNotesEditorRef.current = meetingNotesEditor;
      if (selectedMeeting) {
        getMeetingNotesContent(selectedMeeting);
      }
    },
    onChange: async () => {
      if (selectedMeetingRef.current) {
        await saveMeetingNote();
      }
    }
  });

  useEffect(() => {
    selectedMeetingRef.current = selectedMeeting;
  }, [selectedMeeting]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // Update every minute
    return () => clearInterval(intervalId);
  }, []);

  const getPrepNoteContent = async (meeting) => {
    if (!keys) {
      return;
    }

    const noteRef = doc(db, 'notes', `${currentUser.uid}_${meeting.id}`);
    const noteSnap = await getDoc(noteRef);
    if (noteSnap.exists()) {
      const encryptedContent = noteSnap.data().content;
      try {
        const decryptedContent = await decryptData(encryptedContent, keys.publicKey, keys.privateKey);
        const parsedContent = JSON.parse(decryptedContent);

        if (Array.isArray(parsedContent) && parsedContent.length > 0) {
          setInitialContent(parsedContent);
          if (editorRef.current) {
            editorRef.current.replaceBlocks(editorRef.current.document, parsedContent);
          }
        } else {
          console.warn("Parsed content is not an array or is empty:", parsedContent);
          setInitialContent([{ type: 'paragraph', content: '' }]);
        }
      } catch (error) {
        console.error('Error decrypting note:', error);
        setInitialContent([{ type: 'paragraph', content: 'Error decrypting note' }]);
      }
    } else {
      setInitialContent([{ type: 'paragraph', content: '' }]);
    }
  };

  const getMeetingNotesContent = async (meeting) => {
    if (!keys) {
      return;
    }

    const noteRef = doc(db, 'meetingNotes', `${currentUser.uid}_${meeting.id}`);
    const noteSnap = await getDoc(noteRef);
    if (noteSnap.exists()) {
      const encryptedContent = noteSnap.data().content;
      try {
        const decryptedContent = await decryptData(encryptedContent, keys.publicKey, keys.privateKey);
        const parsedContent = JSON.parse(decryptedContent);

        if (Array.isArray(parsedContent) && parsedContent.length > 0) {
          setMeetingNotesInitialContent(parsedContent); // Set the initial content of the meeting notes editor
          setMeetingNotes(parsedContent); // Update meetingNotes state with block structure
        } else {
          setMeetingNotesInitialContent([{ type: 'paragraph', content: '' }]);
          setMeetingNotes([]); // Reset meetingNotes state
        }
      } catch (error) {
        console.error('Error decrypting meeting notes:', error);
        setMeetingNotesInitialContent([{ type: 'paragraph', content: 'Error decrypting meeting notes' }]);
        setMeetingNotes([{ type: 'paragraph', content: 'Error decrypting meeting notes' }]); // Update meetingNotes state
      }
    } else {
      setMeetingNotesInitialContent([{ type: 'paragraph', content: '' }]);
      setMeetingNotes([]); // Reset meetingNotes state
    }
  };


  const saveMeetingNote = async () => {
    if (!selectedMeetingRef.current || !currentUser || !keys) {
      return;
    }
    const noteRef = doc(db, 'meetingNotes', `${currentUser.uid}_${selectedMeetingRef.current.id}`);
    try {
      const content = JSON.stringify(meetingNotesEditor.document);
      const encryptedContent = await encryptData(content, keys.publicKey);
      await setDoc(noteRef, { content: encryptedContent, lastModified: new Date() }, { merge: true });

      // Update the meetingNotes state with the saved content
      setMeetingNotes(meetingNotesEditor.document);
    } catch (error) {
      console.error('Error saving meeting note:', error);
    }
  };

  useEffect(() => {
    if (selectedMeeting && editorRef.current) {
      const emptyContent = [{ type: 'paragraph', content: '' }];
      editorRef.current.replaceBlocks(editorRef.current.document, []);
      console.log('Editor content after reset on meeting selection:', editorRef.current.document);
      getPrepNoteContent(selectedMeeting);
    }
    if (selectedMeeting && meetingNotesEditorRef.current) {
      const emptyContent = [{ type: 'paragraph', content: '' }];
      meetingNotesEditorRef.current.replaceBlocks(meetingNotesEditorRef.current.document, []);
      console.log('Meeting notes editor content after reset on meeting selection:', meetingNotesEditorRef.current.document);
      getMeetingNotesContent(selectedMeeting);
    }
  }, [selectedMeeting]);

  useEffect(() => {
    if (editor && Array.isArray(initialContent)) {
      const replaceEditorBlocks = async () => {
        try {
          await editor.replaceBlocks(editor.document, initialContent);
        } catch (error) {
          console.error("Error replacing blocks in the editor:", error);
        }
      };

      replaceEditorBlocks();
    }
  }, [initialContent, editor]);

  useEffect(() => {
    if (meetingNotesEditor && Array.isArray(meetingNotesInitialContent)) {
      const replaceEditorBlocks = async () => {
        try {
          await meetingNotesEditor.replaceBlocks(meetingNotesEditor.document, meetingNotesInitialContent);
        } catch (error) {
          console.error("Error replacing blocks in the meeting notes editor:", error);
        }
      };

      replaceEditorBlocks();
    }
  }, [meetingNotesInitialContent, meetingNotesEditor]);

  const handleMeetingSelection = (meeting) => {
    setSelectedMeeting(meeting);
    setSelectedMeetingId(meeting.id);
    selectedMeetingRef.current = meeting;
    getPrepNoteContent(meeting);
    getMeetingNotesContent(meeting);
  };

  const fetchFirestoreMeetings = () => {
    const meetingsRef = collection(db, "meetings");
    const q = query(meetingsRef,
      where("createdBy", "==", currentUser.uid),
      orderBy("start.dateTime", "desc"),
      limit(5));

    return onSnapshot(q, (querySnapshot) => {
      const allMeetings = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        start: { dateTime: doc.data().start.dateTime },
        end: { dateTime: doc.data().end.dateTime }
      }));

      setMeetings(allMeetings);

      if (allMeetings.length > 0) {
        // Preserve the currently selected meeting if it exists in the new fetch
        const currentSelectedMeeting = allMeetings.find(meeting => meeting.id === selectedMeetingRef.current?.id);
        if (currentSelectedMeeting) {
          setSelectedMeeting(currentSelectedMeeting);
          setSelectedMeetingId(currentSelectedMeeting.id);
          selectedMeetingRef.current = currentSelectedMeeting;
          getPrepNoteContent(currentSelectedMeeting); // Fetch the note content for the current meeting
          getMeetingNotesContent(currentSelectedMeeting); // Fetch the meeting notes content for the current meeting
        } else {
          // Default to the first meeting
          setSelectedMeeting(allMeetings[0]);
          setSelectedMeetingId(allMeetings[0].id);
          selectedMeetingRef.current = allMeetings[0];
          getPrepNoteContent(allMeetings[0]); // Fetch the note content for the first meeting
          getMeetingNotesContent(allMeetings[0]); // Fetch the meeting notes content for the first meeting
        }
      }
    }, (error) => {
      console.error("Error fetching Firestore meetings:", error);
    });
  };

  useEffect(() => {
    if (currentUser && currentUser.uid) {
      const unsubscribe = fetchFirestoreMeetings();
      return () => unsubscribe;
    } else {
      setMeetings(demoMeetings);
      setSelectedMeeting(demoMeetings[0]);
      setSelectedMeetingId(demoMeetings[0].id);
      selectedMeetingRef.current = demoMeetings[0];
      setInitialContent(demoContent);
      setMeetingNotesInitialContent(demoContent);
    }
  }, [currentUser]);

  const formatDate = (date) => {
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
    });
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const handleTitleChange = async (event) => {
    const newTitle = event.target.textContent.trim();

    // If the new title is empty, set a placeholder
    if (!newTitle) {
      event.target.textContent = "Enter meeting title";
      setSelectedMeeting(prevMeeting => ({
        ...prevMeeting,
        summary: "Enter meeting title",
      }));
      return;
    }

    if (!selectedMeeting) return;

    // Update Firestore with the new title if user is authenticated
    if (currentUser) {
      const meetingRef = doc(db, "meetings", selectedMeeting.id);
      try {
        await setDoc(meetingRef, { summary: newTitle }, { merge: true });
        setSelectedMeeting(prevMeeting => ({
          ...prevMeeting,
          summary: newTitle,
        }));
      } catch (error) {
        console.error('Error updating title:', error);
      }
    } else {
      setSelectedMeeting(prevMeeting => ({
        ...prevMeeting,
        summary: newTitle,
      }));
    }
  };

  const handleStartChange = async (date) => {
    if (!selectedMeeting || !selectedMeetingRef.current) return;

    try {
      const meetingRef = doc(db, "meetings", selectedMeetingRef.current.id);
      const updatedMeeting = {
        ...selectedMeetingRef.current,
        start: { dateTime: date.toISOString() },
      };

      // Update Firestore document
      await setDoc(meetingRef, {
        start: { dateTime: date.toISOString() },
      }, { merge: true });

      // Update the state with the new meeting start time
      setSelectedMeeting(updatedMeeting);
      selectedMeetingRef.current = updatedMeeting;

      console.log("Updated selectedMeetingRef after update:", selectedMeetingRef.current);

      setIsEditingStart(false);
    } catch (error) {
      console.error("Error updating meeting start time:", error);
    }
  };

  const handleEndChange = async (date) => {
    try {
      const meetingRef = doc(db, "meetings", selectedMeetingRef.current.id);
      await setDoc(meetingRef, {
        end: { dateTime: date.toISOString() },
      }, { merge: true });

      setSelectedMeeting(prevMeeting => ({
        ...prevMeeting,
        end: { dateTime: date.toISOString() },
      }));
      setIsEditingEnd(false);
    } catch (error) {
      console.error("Error updating meeting end time:", error);
    }
  };

  const handleDateChange = async (date) => {
    const start = new Date(selectedMeeting.start.dateTime);
    const end = new Date(selectedMeeting.end.dateTime);

    start.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    end.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());

    try {
      const meetingRef = doc(db, "meetings", selectedMeetingRef.current.id);
      await setDoc(meetingRef, {
        start: { dateTime: start.toISOString() },
        end: { dateTime: end.toISOString() }
      }, { merge: true });

      setSelectedMeeting(prevMeeting => ({
        ...prevMeeting,
        start: { dateTime: start.toISOString() },
        end: { dateTime: end.toISOString() }
      }));
      setIsEditingDate(false);
    } catch (error) {
      console.error("Error updating meeting date:", error);
    }
  };

  const handleAddMeeting = async (name, start, end) => {
    console.log('Adding meeting:', { name, start, end });

    if (!name.trim() || !start || !end) {
      alert("Please enter all required fields.");
      console.log('Missing required fields:', { name, start, end });
      return;
    }

    const newMeeting = {
      id: `custom_${new Date().getTime()}`,
      createdBy: currentUser.uid,
      summary: name,
      hangoutLink: 'https://example.com/custom_meeting',
      start: { dateTime: start.toISOString() },
      end: { dateTime: end.toISOString() }
    };

    try {
      const meetingRef = doc(db, "meetings", newMeeting.id);
      await setDoc(meetingRef, newMeeting);

      console.log('Meeting added to Firestore:', newMeeting);

      setMeetings(prevMeetings => {
        console.log('Updating meetings state:', [...prevMeetings, newMeeting]);
        return [...prevMeetings, newMeeting];
      });

      // Reset initial content for the new meeting
      const emptyContent = [{ type: 'paragraph', content: '' }];
      setInitialContent(emptyContent);
      setMeetingNotesInitialContent(emptyContent);
      setMeetingNotes(emptyContent); // Reset meeting notes state

      // Select the new meeting immediately
      setSelectedMeeting(newMeeting);
      setSelectedMeetingId(newMeeting.id);
      selectedMeetingRef.current = newMeeting;

      console.log('Selected meeting:', newMeeting);

      // Clear the editors by setting them to an empty block
      if (editorRef.current) {
        editorRef.current.replaceBlocks(editorRef.current.document, emptyContent);
        console.log('Editor content after reset:', editorRef.current.document);
      }
      if (meetingNotesEditorRef.current) {
        meetingNotesEditorRef.current.replaceBlocks(meetingNotesEditorRef.current.document, emptyContent);
        console.log('Meeting notes editor content after reset:', meetingNotesEditorRef.current.document);
      }
    } catch (error) {
      console.error("Error adding meeting to Firestore:", error);
    }
  };





  const handleCreateMeetingClick = () => {
    const defaultName = 'New Meeting';
    const defaultStart = new Date();
    const defaultEnd = new Date(new Date().getTime() + 3600000); // 1 hour later
    handleAddMeeting(defaultName, defaultStart, defaultEnd);
  };

  const handleArchiveClick = () => {
    onSetActivePage('notesArchive');
  };

  const handleEditorChange = (newNotes) => {
    setMeetingNotes(newNotes);
  };

  const inputStyle = {
    border: 'none',
    background: 'transparent',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    color: 'inherit',
    textAlign: 'center',
    width: '10px', /* Ensure input does not collapse */
  };

  const isFutureMeeting = selectedMeeting && new Date(selectedMeeting.start.dateTime) > new Date();

  return (
    <div key={componentKey} className={styles.container}>
      {showIntro && (
        <div className={styles.introOverlay}>
          <Intro userId={currentUser.uid} onClose={handleCloseIntro} />
        </div>
      )}
      <div className={styles.contentContainer}>
        <div className={styles.addMeetingDiv}>
          <button onClick={handleCreateMeetingClick} className={styles.buttonAddMeeting}>
            <img src={plus} alt="Add new meeting" />
            New Meeting
          </button>
          <select
            className={styles.meetingDropdown}
            value={selectedMeetingId || ''}
            onChange={(e) => {
              const selected = meetings.find((meeting) => meeting.id === e.target.value);
              setSelectedMeeting(selected);
              setSelectedMeetingId(selected.id);
              getPrepNoteContent(selected); // Fetch the note content for the selected meeting
              getMeetingNotesContent(selected); // Fetch the meeting notes content for the selected meeting
            }}
          >
            <optgroup label="Past 5 Meetings">
              {meetings
                .filter(meeting => new Date(meeting.start.dateTime) <= new Date())
                .map((meeting) => (
                  <option key={meeting.id} value={meeting.id}>
                    {meeting.summary}
                  </option>
                ))}
            </optgroup>
            <optgroup label="Future Meetings">
              {meetings
                .filter(meeting => new Date(meeting.start.dateTime) > new Date())
                .map((meeting) => (
                  <option key={meeting.id} value={meeting.id}>
                    {meeting.summary}
                  </option>
                ))}
            </optgroup>
          </select>
          <AIActionItems meetingNotes={meetingNotes} onUpdateMeetingNotes={setMeetingNotesInitialContent} /> {/* Pass meetingNotes and the update function */}
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.meetingNotesContainer}>
            {selectedMeeting ? (
              <div className={styles.selectedMeeting}>
                <div className={styles.datePickerWrapper}>
                  <DatePicker
                    selected={new Date(selectedMeeting.start.dateTime)}
                    onChange={handleStartChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    dateFormat="HH:mm"
                    timeCaption="Start Time"
                    customInput={<input className={styles.customInput} style={inputStyle} />}
                    popperClassName="custom-datepicker-popper"
                  />
                  <span> - </span>
                  <DatePicker
                    selected={new Date(selectedMeeting.end.dateTime)}
                    onChange={handleEndChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    dateFormat="HH:mm"
                    timeCaption="End Time"
                    customInput={<input className={styles.customInput} style={inputStyle} />}
                    popperClassName="custom-datepicker-popper"
                  />
                  <span> | </span>
                  <DatePicker
                    selected={new Date(selectedMeeting.start.dateTime)}
                    onChange={handleDateChange}
                    dateFormat="MMMM d"
                    customInput={<input className={styles.customInput} style={inputStyle} />}
                    popperClassName="custom-datepicker-popper"
                  />
                  {isFutureMeeting && <div className={styles.futureTag}>
                    <p>Meeting is in the future, good luck preparing some</p>
                  </div>}
                </div>

                <h2
                  ref={editableTitleRef}
                  contentEditable
                  suppressContentEditableWarning
                  onFocus={(e) => {
                    if (e.target.textContent === "Enter meeting title") {
                      e.target.innerHTML = "";
                    }
                  }}
                  onBlur={(e) => {
                    if (!e.target.textContent.trim()) {
                      e.target.innerHTML = "<span class='placeholder'>Enter meeting title</span>";
                    }
                    handleTitleChange(e);
                  }}
                  className={styles.editableTitle}
                  dangerouslySetInnerHTML={{ __html: selectedMeeting.summary || "<span class='placeholder'>Enter meeting title</span>" }}
                >
                </h2>
              </div>
            ) : (
              <Skeleton />
            )}
            <div className={`${styles.meetingNotesEditor} light-theme-editor`}>
              <BlockNoteView
                editor={meetingNotesEditor}
                onChange={async () => currentUser && await saveMeetingNote()}

              />
            </div>
          </div>
        </div>

        <button className={styles.buttonGoToArchive} onClick={handleArchiveClick}>
          → Your Notes Archive
        </button>
      </div>
    </div>
  );
};

export default MeetingNotes;
