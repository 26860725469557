import React, { useState } from 'react';

function NavBar({ activePage, setActivePage }) {
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const handleSelect = (page) => {
        setActivePage(page);
        setDropdownOpen(false);
    };

    const navButtonStyle = (page, isLast) => ({
        width: 100,
        height: 40,
        backgroundColor: activePage === page ? '#1F1F1F' : 'white',
        color: activePage === page ? 'white' : 'black',
        borderRadius: 5,
        fontFamily:'Inter',
        fontWeight:'600',
        border: 'none',
        fontSize: 12,
        fontFamily: 'Roboto',
        fontWeight: '500',
        lineHeight: '20px',
        padding: '0px',
        cursor: 'pointer',
        marginRight: isLast ? '0px' : '10px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    });

    const navContainerStyle = {
        position: 'fixed',
        bottom: 15,
        left: '50%',
        transform: 'translateX(-50%)',
        background: '#FFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
        boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.25)',
        borderRadius: 15,
        flexDirection: 'row',
        transition: 'max-width 0.3s ease-in-out',
        maxWidth: (activePage === 'meetingNotes' || activePage === 'prepNotes' || activePage === 'insideMeeting' || activePage === 'notesArchive') ? '400px' : '200px',
    };

    if (['prepNotes', 'meetingNotes', 'notesArchive'].includes(activePage)) {
        return (
            <div style={navContainerStyle}>
                <button onClick={() => handleSelect('notesArchive')} style={navButtonStyle('notesArchive', true)}>
                    📄 Notes archive
                </button>
            </div>
        );
    } else {
        return null;
    }
}

export default NavBar;


