import React, { useState, useContext } from 'react';
import { db } from '../firebase';  // Import Firebase configuration and db instance
import { AuthContext } from '../App';  // Import the AuthContext for user info
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';  // Firestore methods

function FeedbackForm({ onClose }) {
    const { currentUser } = useContext(AuthContext);  // Access the current user from context
    const [feedback, setFeedback] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!feedback.trim()) {
            alert("Please enter some feedback.");
            return;
        }
        if (!currentUser) {
            alert("You must be logged in to submit feedback.");
            return;
        }

        const feedbackId = `${currentUser.uid}_${new Date().getTime()}`;  // Unique ID for each feedback based on user and timestamp
        const feedbackRef = doc(db, 'feedback', feedbackId);  // Reference to a new doc in 'feedback' collection

        setIsSubmitting(true);
        try {
            await setDoc(feedbackRef, {
                userId: currentUser.uid,
                content: feedback,
                timestamp: serverTimestamp()  // Server timestamp for consistency
            });
            alert("Feedback submitted successfully!");
            setFeedback('');
        } catch (error) {
            console.error("Error submitting feedback:", error);
            alert("Error submitting feedback, please try again.");
        } finally {
            setIsSubmitting(false);
            onClose();  // Close the form
        }
    };

    return (
        <div style={{
            position: 'fixed',
            top: '20%',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: 'white',
            padding: '20px',
            zIndex: 1000,
            boxShadow: '0px 0px 8px rgba(0,0,0,0.25)',
            borderRadius: '8px'
        }}>
            <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                style={{ width: '300px', height: '100px', fontFamily:'Inter', fontSize:'14px', border:'none' }}
                placeholder="Every feedback will be red by jordi"
            />
            <br />
            <button style={{background:'transparent', border:'none', cursor:'pointer'}} onClick={handleSubmit} disabled={isSubmitting}>
                {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
            </button>
            <button style={{background:'transparent', border:'none', cursor:'pointer', marginLeft: '10px'}} onClick={onClose}>Close</button>
        </div>
    );
}

export default FeedbackForm;
