import React, { useState } from 'react';
import styles from './IntroStyles.module.css'; // Add your styles here
import backOffice from '../../assets/backOffice.svg';

const introScreens = [
  {
    id: 1,
    title: "That’s your own notes back-office!",
    description: "Ever had the feeling of taking notes and then leave them abandoned, unorganized, cluttering the workspace? Your notes are now sorted into calendar weeks. You’ll never forget anything anymore!",
    image: backOffice
  }
];

const NotesArchiveIntro = ({ onClose }) => {
  const [currentScreen, setCurrentScreen] = useState(0);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const handleCheckboxChange = () => {
    setDontShowAgain(!dontShowAgain);
  };

  const handleClose = () => {
    onClose(dontShowAgain);
  };

  const handleNext = () => {
    if (currentScreen < introScreens.length - 1) {
      setCurrentScreen(currentScreen + 1);
    } else {
      handleClose();
    }
  };

  const { title, description, image } = introScreens[currentScreen];

  return (
    <div className={styles.intro}>
      <div className={styles.content}>
        <div className={styles.header}>
          <img src={image} alt="Custom Graphic" className={styles.iconFolder} />
        </div>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.description}>{description}</p>
        <div className={styles.actions}>
          <button onClick={handleNext} className={styles.primaryButton}>
            {currentScreen === introScreens.length - 1 ? "Let's go" : "Next"}
          </button>
          <button onClick={handleClose} className={styles.secondaryButton}>Skip</button>
        </div>
        <label className={styles.checkboxLabel}>
          <input type="checkbox" checked={dontShowAgain} onChange={handleCheckboxChange} />
          Don't show again
        </label>
      </div>
    </div>
  );
};

export default NotesArchiveIntro;
