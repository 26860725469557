import React, { createContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { gapi } from 'gapi-script';

export const GoogleApiContext = createContext();

export const GoogleApiProvider = ({ children }) => {
    const [isApiReady, setApiReady] = useState(false);
    const [isSignedIn, setSignedIn] = useState(false);

    useEffect(() => {
        const auth = getAuth();

        const loadGapi = () => {
            return new Promise((resolve) => {
                gapi.load('client:auth2', resolve);
            });
        };

        const initClient = (token) => {
            gapi.client.init({
                clientId: '464096630786-c6ed2imh7kmljoauqesi3tp4f2adl7vf.apps.googleusercontent.com', // Replace with your actual client ID
                discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
                scope: 'https://www.googleapis.com/auth/calendar.readonly',
            }).then(() => {
                gapi.auth.setToken({ access_token: token });
                setApiReady(true);
            }).catch(error => {
                console.error('Error initializing GAPI client:', error);
            });
        };

        onAuthStateChanged(auth, async (user) => {
            if (user) {
                await loadGapi();
                user.getIdToken().then((token) => {
                    setSignedIn(true);
                    initClient(token);
                }).catch(error => {
                    console.error('Error getting ID token:', error);
                });
            } else {
                setSignedIn(false);
                setApiReady(false);
            }
        });
    }, []);

    const signIn = () => {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        signInWithRedirect(auth, provider);
    };

    return (
        <GoogleApiContext.Provider value={{ isApiReady, isSignedIn, signIn }}>
            {children}
        </GoogleApiContext.Provider>
    );
};

