  import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
  import { db } from '../firebase';
  import { collection, query, where, onSnapshot, getDoc, doc, deleteDoc, setDoc } from 'firebase/firestore';
  import { AuthContext } from '../App';
  import styles from './NotesArchive.module.css';
  import menuDots from '../assets/menu-dots.svg';
  import { decryptData, encryptData } from './encryption/cryptoUtils';
  import { useKeys } from './encryption/KeyContext';
  import { useCreateBlockNote } from '@blocknote/react';
  import { BlockNoteView } from '@blocknote/mantine';
  import '@blocknote/mantine/style.css';
  import './lightTheme.css';
  import Overlay from './Overlay';
  import search from '../assets/search.svg';
  import sparkles from '../assets/sparkles.svg';
  import RiveComponent from '@rive-app/react-canvas';
  import folderIcon from '../assets/folder-list.svg';
  import collapseIcon from '../assets/arrow-down.svg';
  import expandIcon from '../assets/folder.svg';
  import allFoldersIcon from '../assets/all-folders.svg';
  import NotesArchiveIntro from './Introduction/NotesArchiveIntro'; // Import the NotesArchiveIntro component
  import { saveAs } from 'file-saver';

  const lightTheme = {
    // Define your theme here...
  };

  const NotesArchive = ({ onSetActivePage }) => {
    const { keys } = useKeys();
    const { currentUser } = useContext(AuthContext);
    const [meetings, setMeetings] = useState([]);
    const [filteredMeetings, setFilteredMeetings] = useState([]);
    const [selectedMeeting, setSelectedMeeting] = useState(null);
    const [selectedWeek, setSelectedWeek] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [meetingNotesInitialContent, setMeetingNotesInitialContent] = useState([{ type: 'paragraph', content: '' }]);
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [isFoldersCollapsed, setIsFoldersCollapsed] = useState(false);
    const meetingNotesEditorRef = useRef(null);
    const selectedMeetingRef = useRef(selectedMeeting);
    const [initialContent, setInitialContent] = useState([{ type: 'paragraph', content: '' }]);
    const [showIntro, setShowIntro] = useState(false); // State to control the visibility of the intro screens
    const [introChecked, setIntroChecked] = useState(false); 

    const generateHTMLContent = async (meetings) => {
      let htmlContent = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Meetings Archive</title>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@blocknote/mantine/dist/style.css">
          <style>
            body { font-family: Arial, sans-serif; }
            .meeting { margin-bottom: 20px; }
            .meeting h3 { margin: 0; }
            .meeting p { margin: 5px 0; }
            .block-editor-content { padding: 10px; border: 1px solid #ddd; background: #f9f9f9; }
            /* Add other BlockNote Editor specific styles here */
          </style>
        </head>
        <body>
          <h1>Meetings Archive</h1>
      `;
  
      for (const meeting of meetings) {
        const startDate = meeting.start ? meeting.start.toLocaleString() : 'N/A';
        const endDate = meeting.end ? meeting.end.toLocaleString() : 'N/A';
        const notes = await getMeetingNotesContentForDownload(meeting);
        
        htmlContent += `
          <div class="meeting">
            <h3>${meeting.summary}</h3>
            <p>Start: ${startDate}</p>
            <p>End: ${endDate}</p>
            <div class="block-editor-content">${notes}</div>
          </div>
        `;
      }
  
      htmlContent += `
        </body>
        </html>
      `;
  
      return htmlContent;
    };
  
    const getMeetingNotesContentForDownload = async (meeting) => {
      if (!keys) {
        return 'Unable to fetch notes.';
      }
  
      const noteRef = doc(db, 'meetingNotes', `${currentUser.uid}_${meeting.id}`);
      const noteSnap = await getDoc(noteRef);
      if (noteSnap.exists()) {
        const encryptedContent = noteSnap.data().content;
        try {
          const decryptedContent = await decryptData(encryptedContent, keys.publicKey, keys.privateKey);
          const parsedContent = JSON.parse(decryptedContent);
  
          // Convert parsed content to HTML
          const notesHtml = parsedContent.map(block => {
            return convertBlockToHTML(block);
          }).join('');
  
          return notesHtml;
        } catch (error) {
          console.error('Error decrypting meeting notes:', error);
          return 'Error decrypting meeting notes.';
        }
      } else {
        return 'No notes available.';
      }
    };
  
    const convertBlockToHTML = (block) => {
      switch (block.type) {
        case 'paragraph':
          return `<p style="${convertStyles(block.styles)}">${convertContent(block.content)}</p>`;
        case 'heading':
          return `<h${block.level} style="${convertStyles(block.styles)}">${convertContent(block.content)}</h${block.level}>`;
        case 'list':
          const items = block.items.map(item => `<li>${convertContent(item)}</li>`).join('');
          return `<ul style="${convertStyles(block.styles)}">${items}</ul>`;
        case 'table':
          const rows = block.rows.map(row => {
            const cells = row.cells.map(cell => `<td style="${convertStyles(cell.styles)}">${convertContent(cell.content)}</td>`).join('');
            return `<tr>${cells}</tr>`;
          }).join('');
          return `<table style="${convertStyles(block.styles)}">${rows}</table>`;
        // Add cases for other block types as needed
        default:
          return '';
      }
    };
  
    const convertStyles = (styles) => {
      if (!styles) return '';
      return Object.entries(styles).map(([key, value]) => `${key}: ${value};`).join(' ');
    };
  
    const convertContent = (content) => {
      if (typeof content === 'string') {
        return content;
      }
  
      if (Array.isArray(content)) {
        return content.map(convertContent).join('');
      }
  
      if (typeof content === 'object') {
        // Handle object content conversion
        let result = '';
        for (const [key, value] of Object.entries(content)) {
          result += `<span>${key}: ${convertContent(value)}</span>`;
        }
        return result;
      }
  
      return '';
    };
  
    const downloadAsHTML = async () => {
      const htmlContent = await generateHTMLContent(meetings);
      const blob = new Blob([htmlContent], { type: 'text/html' });
      saveAs(blob, 'meetings_archive.html');
    };
  
    
    const MeetingNotesEditor = useCreateBlockNote({
      initialContent: meetingNotesInitialContent,
      onReady: (editorInstance) => {
        meetingNotesEditorRef.current = editorInstance;
        if (selectedMeeting) {
          getMeetingNotesContent(selectedMeeting);
        }
      },
      onChange: async () => {
        if (selectedMeetingRef.current) {
          await saveMeetingNote();
        }
      }
    });

    useEffect(() => {
      const checkIntroPreference = async () => {
        if (currentUser && currentUser.uid) {
          const userRef = doc(db, 'users', currentUser.uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            setShowIntro(userSnap.data().showNotesArchiveIntro !== false);
          }
        }
        setIntroChecked(true); // Mark intro check as complete
      };

      checkIntroPreference();
    }, [currentUser]);

    const handleCloseIntro = async (dontShowAgain) => {
      if (dontShowAgain) {
        await setDoc(doc(db, 'users', currentUser.uid), { showNotesArchiveIntro: false }, { merge: true });
      }
      setShowIntro(false);
    };


    const playAnimationRef = useRef(null);
    const pauseAnimationRef = useRef(null);

    const handlePlayAnimation = useCallback(() => {
      if (playAnimationRef.current) {
        playAnimationRef.current();
      }
    }, []);

    const handlePauseAnimation = useCallback(() => {
      if (pauseAnimationRef.current) {
        pauseAnimationRef.current();
      }
    }, []);

    useEffect(() => {
      if (!currentUser) {
        return;
      }

      const q = query(
        collection(db, "meetings"),
        where("createdBy", "==", currentUser.uid) // Filter by the current user's ID
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const allMeetings = querySnapshot.docs.map(doc => {
          const data = doc.data();
          const start = data.start?.dateTime ? new Date(data.start.dateTime) : null;
          const end = data.end?.dateTime ? new Date(data.end.dateTime) : null;

          return {
            id: doc.id,
            summary: data.summary || 'No Summary',
            start: start,
            end: end,
            week: start ? `W${start.getWeek()}` : 'Unknown Week'
          };
        });

        setMeetings(allMeetings);
        setFilteredMeetings(allMeetings);
      }, (error) => {
        console.error("Error fetching user meetings:", error);
      });

      return () => unsubscribe();
    }, [currentUser]);

    const getMeetingNotesContent = async (meeting) => {
      if (!keys) {
        return;
      }

      const noteRef = doc(db, 'meetingNotes', `${currentUser.uid}_${meeting.id}`);
      const noteSnap = await getDoc(noteRef);
      if (noteSnap.exists()) {
        const encryptedContent = noteSnap.data().content;
        try {
          const decryptedContent = await decryptData(encryptedContent, keys.publicKey, keys.privateKey);
          const parsedContent = JSON.parse(decryptedContent);

          if (Array.isArray(parsedContent) && parsedContent.length > 0) {
            setMeetingNotesInitialContent(parsedContent);
            if (meetingNotesEditorRef.current) {
              meetingNotesEditorRef.current.replaceBlocks(meetingNotesEditorRef.current.document, parsedContent);
            }
          } else {
            setMeetingNotesInitialContent([{ type: 'paragraph', content: '' }]);
          }
        } catch (error) {
          console.error('Error decrypting meeting notes:', error);
          setMeetingNotesInitialContent([{ type: 'paragraph', content: 'Error decrypting meeting notes' }]);
        }
      } else {
        setMeetingNotesInitialContent([{ type: 'paragraph', content: '' }]);
      }
    };

    useEffect(() => {
      if (selectedMeeting && meetingNotesEditorRef.current) {
        getMeetingNotesContent(selectedMeeting);
      }
    }, [selectedMeeting]);

    useEffect(() => {
      if (MeetingNotesEditor && Array.isArray(meetingNotesInitialContent)) {
        const replaceEditorBlocks = async () => {
          try {
            await MeetingNotesEditor.replaceBlocks(MeetingNotesEditor.document, meetingNotesInitialContent);
          } catch (error) {
            console.error("Error replacing blocks in the meeting notes editor:", error);
          }
        };

        replaceEditorBlocks();
      }
    }, [meetingNotesInitialContent, MeetingNotesEditor]);

    const handleMeetingSelection = (meeting) => {
      setSelectedMeeting(meeting);
      selectedMeetingRef.current = meeting;
      getMeetingNotesContent(meeting);
      setIsOverlayVisible(true);
    };

    const toggleDropdown = (meetingId) => {
      setOpenDropdownId(meetingId === openDropdownId ? null : meetingId);
    };

    const deleteMeeting = async (meetingId) => {
      if (!currentUser) {
        console.error('No current user');
        return;
      }

      const meetingRef = doc(db, "meetings", meetingId);
      const noteRef = doc(db, 'meetingNotes', `${currentUser.uid}_${meetingId}`);

      try {
        await deleteDoc(meetingRef);
        await deleteDoc(noteRef);

        setMeetings(prevMeetings => prevMeetings.filter(meeting => meeting.id !== meetingId));
        setFilteredMeetings(prevFilteredMeetings => prevFilteredMeetings.filter(meeting => meeting.id !== meetingId));
        setSelectedMeeting(null);
        selectedMeetingRef.current = null;

        console.log(`Meeting ${meetingId} deleted successfully`);
      } catch (error) {
        console.error("Error deleting meeting:", error);
      }
    };

    const handleSearchChange = (e) => {
      const term = e.target.value;
      setSearchTerm(term);

      if (term === '') {
        setFilteredMeetings(meetings);
      } else {
        const lowerTerm = term.toLowerCase();
        const filtered = meetings.filter(meeting =>
          meeting.summary.toLowerCase().includes(lowerTerm)
        );
        setFilteredMeetings(filtered);
      }
    };

    const handleWeekSelection = (week) => {
      setSelectedWeek(week);
      setSelectedMeeting(null); // Deselect any meeting when switching weeks
    };

    const toggleFoldersCollapse = () => {
      setIsFoldersCollapsed(prev => !prev);
    };

    const meetingsByWeek = filteredMeetings.reduce((acc, meeting) => {
      const week = meeting.week;
      if (!acc[week]) {
        acc[week] = [];
      }
      acc[week].push(meeting);
      return acc;
    }, {});

    const handleMeetingNotesClick = () => {
      onSetActivePage('meetingNotes');
      handlePlayAnimation();
    };

    const saveMeetingNote = async () => {
      if (!selectedMeetingRef.current || !currentUser || !keys || !meetingNotesEditorRef.current) {
        return;
      }

      const noteRef = doc(db, 'meetingNotes', `${currentUser.uid}_${selectedMeetingRef.current.id}`);
      try {
        const content = JSON.stringify(meetingNotesEditorRef.current.document);
        const encryptedContent = await encryptData(content, keys.publicKey);
        await setDoc(noteRef, { content: encryptedContent, lastModified: new Date() }, { merge: true });
      } catch (error) {
        console.error('Error saving meeting note:', error);
      }
    };

    useEffect(() => {
      if (selectedMeeting && meetingNotesEditorRef.current) {
        getMeetingNotesContent(selectedMeeting);
      }
    }, [selectedMeeting, meetingNotesEditorRef]);

    const handleShowIntro = () => {
      setShowIntro(true);
    };

    return (
      <div className={styles.notesArchiveContainer}>
        <div className={styles.notesArchiveContentDiv}>
          <div className={styles.sidebar}>
          <button className={styles.buttonDownload} onClick={downloadAsHTML}>
      Download as HTML
    </button>
            <div className={styles.searchContainer}>
              <img src={search} alt="Search Icon" className={styles.searchIcon} />
              <input
                type="text"
                className={styles.searchBar}
                placeholder="Search in your notes"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>

            <div className={styles.foldersHeader} onClick={toggleFoldersCollapse}>
              <span>Folders</span>
              <img src={isFoldersCollapsed ? expandIcon : collapseIcon} alt="Toggle" className={styles.toggleIcon} />
            </div>
            {!isFoldersCollapsed && (
              <>
                <div
                  className={`${styles.folderItem} ${selectedWeek === null ? styles.folderItemSelected : ''}`}
                  onClick={() => setSelectedWeek(null)}
                >
                  <img src={allFoldersIcon} alt="All Folders" className={styles.folderIcon} />
                  <span>All Folders</span>
                </div>
                <div className={styles.folderList}>
                  {Object.keys(meetingsByWeek).map(week => (
                    <div
                      key={week}
                      className={`${styles.folderItem} ${selectedWeek === week ? styles.folderItemSelected : ''}`}
                      onClick={() => handleWeekSelection(week)}
                    >
                      <img src={folderIcon} alt="Folder" className={styles.folderIcon} />
                      <span>{week}</span>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className={styles.mainMeetingDiv}>
            <div className={styles.mainContent}>
              {selectedWeek === null ? (
                <div className={styles.allFolders}>
                  <h2 className={styles.allFoldersHeader}>All Folders</h2>
                  <div className={styles.folderGrid}>
                    {Object.keys(meetingsByWeek).map(week => (
                      <div
                        key={week}
                        className={styles.folderGridItem}
                        onClick={() => handleWeekSelection(week)}
                      >
                        <img src={folderIcon} alt="Folder" className={styles.folderIconLarge} />
                        <span>{week}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className={styles.weekMeetings}>
                  <h2 className={styles.weekHeader}><span>{selectedWeek} (read only)</span></h2>
                  <ul className={styles.meetingList}>
                    {meetingsByWeek[selectedWeek].map(meeting => (
                      <li
                        key={meeting.id}
                        className={`${styles.meetingListItem} ${selectedMeeting?.id === meeting.id ? styles.meetingListItemSelected : ''}`}
                        onClick={() => handleMeetingSelection(meeting)}
                      >
                        <div>
                          <div className={styles.meetingSummary}>{meeting.summary}</div>
                          <div className={styles.meetingDate}>
                            {meeting.start.toLocaleDateString(undefined, { day: '2-digit', month: 'long', year: 'numeric' })}
                          </div>
                        </div>
                        <div className={styles.menuDots} onClick={(e) => {
                          e.stopPropagation();
                          toggleDropdown(meeting.id);
                        }}>
                          <img style={{ height: '14px' }} src={menuDots} alt="Menu" />
                        </div>
                        {openDropdownId === meeting.id && (
                          <div className={styles.dropdownMenu}>
                            <div className={`${styles.dropdownItem} ${styles.danger}`}
                              onClick={() => {
                                deleteMeeting(meeting.id);
                                setOpenDropdownId(null);
                              }}>
                              Delete
                            </div>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className={styles.notesContainer}>
              {selectedWeek !== null && (
                <div className={styles.notesContainer}>
                  {selectedMeeting && (
                    <div className={styles.noteEditorContainer}>
                      <h2 className={styles.meetingNameHeader}>{selectedMeeting.summary}</h2>
                      <BlockNoteView editor={MeetingNotesEditor} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <button className={styles.buttonGoToMeetingNotes} onClick={handleMeetingNotesClick}>
          ← Meeting Notes
        </button>
        {showIntro && <NotesArchiveIntro onClose={handleCloseIntro} />}
      </div>
    );
  }
  export default NotesArchive;

  // Helper function to get the ISO week number
  Date.prototype.getWeek = function () {
    const date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    const week1 = new Date(date.getFullYear(), 0, 4);
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
  };
